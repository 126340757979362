<template>
  <div>
    <!-- 连锁的菜单配置(这里连锁账号是分权限控制了的，单门店没有控制) -->
    <div
      class="left-content"
      :style="{ width: isCollapse ? '' : '200px' }"
      v-if="isChain == 1"
    >
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-vertical-demo"
        active-text-color="#fff"
        router
        :collapse="isCollapse"
        :collapse-transition="false"
        unique-opened
      >
        <el-menu-item index="/home">
          <i class="iconfont icon-shouye"></i>
          <span>首页</span>
        </el-menu-item>
        <el-submenu index="/shop" v-if="identity==1 || identity==2">
          <template slot="title">
            <i class="iconfont icon-xianxiamendian"></i>
            <span>门店管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/shop/shopList">门店列表</el-menu-item>
            <el-menu-item index="/shop/shopMember">预警名单</el-menu-item>
            <el-menu-item index="/shop/shopBlacklist">黑名单</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/goods" v-if="identity==1 || identity==2">
          <template slot="title">
            <i class="iconfont icon-shangpin"></i>
            <span>商品管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/goods/goodsList" >商品列表</el-menu-item>
            <el-menu-item index="/goods/goodsStatistics">商品统计</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/order" v-if="identity==1 || identity==2 || identity==3">
          <template slot="title">
            <i class="iconfont icon-dingdan-quanbudingdan"></i>
            <span>订单管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/order/orderAll" v-if="identity==1 || identity==2">全部订单</el-menu-item>
            <!-- <el-menu-item index="/order/orderWarn">报警订单</el-menu-item> -->
            <el-menu-item index="/order/unclaimed" v-if="identity==1 || identity==2">待领取</el-menu-item>
            <el-menu-item index="/order/received">已领取</el-menu-item>
            <el-menu-item index="/order/refunded">已退款</el-menu-item>
            <el-menu-item index="/order/orderTabel" v-if="identity==1 || identity==2">订单评价</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/check" v-if="identity==1 || identity==2 || identity==3">
          <template slot="title">
            <i class="iconfont icon-licai"></i>
            <span>结算管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/check/checkAcc" v-if="identity==1 ||identity==3">结算账户</el-menu-item>
            <el-menu-item index="/check/checkInfo" v-if="identity==1 ||identity==3">开票信息</el-menu-item>
            <el-menu-item index="/check/checkList" v-if="identity==1 ||identity==3">结算列表</el-menu-item>
            <el-menu-item index="/shop/shopSett"  v-if="identity==1 ||identity==3">结算记录</el-menu-item>
            <el-menu-item index="/check/orderInfo" v-if="identity==1 || identity==2 || identity==3">订单记录</el-menu-item>
            <el-menu-item index="/check/goodsInfo" v-if="identity==1 || identity==2 || identity==3">商品记录</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/stock" v-if="identity==1 || identity==2 || identity==3">
          <template slot="title">
            <i class="iconfont icon-kucun_crm"></i>
            <span>库存管理</span>
          </template>
          <el-menu-item-group>
            <!-- 超市商品库 -->
            <el-submenu index="/stock/goodsStock" v-if="identity==1&&is_supermarket==1 || identity==2&&is_supermarket==1">
              <template slot="title">商品库</template>
              <el-menu-item index="/stock/goodsStock/freshgoodsStock">生鲜商品库</el-menu-item>
              <el-menu-item index="/stock/goodsStock/NofreshgoodsStock">非生鲜商品库</el-menu-item>
            </el-submenu>
            <!-- 非超市商品库 -->
            <el-menu-item v-if="identity==1&&is_supermarket!=1 || identity==2&&is_supermarket!=1" index="/stock/goodsStock1">商品库</el-menu-item>

            <!-- 超市出库记录 -->
            <el-submenu index="/stock/outStock" v-if="is_supermarket==1">
              <template slot="title">出库记录</template>
              <el-menu-item index="/stock/outStock/freshoutStock">生鲜出库记录</el-menu-item>
              <el-menu-item index="/stock/outStock/outStock">非生鲜出库记录</el-menu-item>
            </el-submenu>
            <!-- 非超市出库记录 -->
            <el-menu-item v-if="is_supermarket!=1" index="/stock/outStock1">出库记录</el-menu-item>

            <!-- <el-menu-item :index="is_supermarket==1?'/stock/outStock':'/stock/outStock1'" :style=" {'padding-left': is_supermarket==1?'40px !important':''}">出库记录</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/operateStatistic" v-if="identity==1 || identity==2">
          <template slot="title">
            <i class="el-icon-user-solid" style="margin-right: 14px;"></i>
            <span>统计分析</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/operateStatistic/weekChain">周动销</el-menu-item>
            <el-menu-item index="/operateStatistic/core">运营趋势</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/account" v-if="identity==1">
          <template slot="title">
            <i class="el-icon-user-solid" style="margin-right: 14px;"></i>
            <span>账号管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/account/account">账号设置</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/OperationLog" v-if="identity==1&&is_supermarket==0 || identity==2&&is_supermarket==0">
          <template slot="title">
            <i class="iconfont icon-licai"></i>
            <span>操作日志</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/OperationLog/OperationLog">日志列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
    <!-- 门店的菜单配置 -->
    <div
      class="left-content"
      :style="{ width: isCollapse ? '' : '200px' }"
      v-else
    >
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-vertical-demo"
        active-text-color="#fff"
        router
        :collapse="isCollapse"
        :collapse-transition="false"
        unique-opened
      >
        <el-menu-item index="/home">
          <i class="iconfont icon-shouye"></i>
          <span>首页</span>
        </el-menu-item>
        <el-submenu index="/goods1">
          <template slot="title">
            <i class="iconfont icon-shangpin"></i>
            <span>商品管理</span>
          </template>
          <el-menu-item-group>
            <!-- <el-menu-item index="/goods/changeGoods1">超市品类列表</el-menu-item>
            <el-menu-item index="/goods/goodsList1">超市商品列表</el-menu-item> -->
            <el-menu-item index="/goods/goodsList1">商品列表</el-menu-item>
            <el-menu-item index="/goods/changeGoods1" >商品统计</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/order1">
          <template slot="title">
            <i class="iconfont icon-shangpin"></i>
            <span>订单管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/order/orderAll1">全部订单</el-menu-item>
            <el-menu-item index="/order/unclaimed1">待领取</el-menu-item>
            <el-menu-item index="/order/received1">已领取</el-menu-item>
            <el-menu-item index="/order/refunded1">已退款</el-menu-item>
            <el-menu-item index="/order/orderTabel1">订单评价</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/check">
          <template slot="title">
            <i class="iconfont icon-licai"></i>
            <span>结算管理</span>
          </template>
          <el-menu-item-group>
            <!-- <el-menu-item index="/check/checkAcc">结算账户</el-menu-item> -->
            <!-- <el-menu-item index="/check/checkInfo">开票信息</el-menu-item> -->
            <el-menu-item index="/check/checkList">结算列表</el-menu-item>
            <!-- <el-menu-item index="/shop/shopSett">结算记录</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/stock1">
          <template slot="title">
            <i class="iconfont icon-kucun_crm"></i>
            <span>库存管理</span>
          </template>
          <el-menu-item-group>
            <el-submenu index="/stock1/goodsStock">
              <template slot="title">商品库</template>
              <el-menu-item index="/stock/goodsStock/goodsStock2">生鲜商品库</el-menu-item>
              <el-menu-item index="/stock/goodsStock/NogoodsStock2">非生鲜商品库</el-menu-item>
            </el-submenu>
            <el-submenu index="/stock/outStock">
              <template slot="title">出库记录</template>
              <el-menu-item index="/stock/outStock/freshoutStock2">生鲜出库记录</el-menu-item>
              <el-menu-item index="/stock/outStock/outStock2">非生鲜出库记录</el-menu-item>
            </el-submenu>
            <!-- <el-menu-item index="/stock/outStock2" style=" padding-left: 40px !important;" >出库记录</el-menu-item> -->
            <!-- <el-menu-item index="/stock/yesterdayGood2">昨日留存</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local.js"
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: "false",
      isChain:'',
      identity:''//管理员1、运营2、财务3
    },
  },
  data() {
    return {}
  },
  created() {
    let fullWidth = document.documentElement.clientWidth
    if (fullWidth > 1100) {
      this.isCollapse = false
    } else {
      this.isCollapse = true
    }
    this.isChain = local.get("user")
    this.is_supermarket=local.get("is_supermarket")
    this.identity=local.get("identity")
  },
  methods: {
    click() {
      this.isCollapse = !this.isCollapse
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        let fullWidth = document.documentElement.clientWidth
        if (fullWidth > 1100) {
          this.isCollapse = false
        } else {
          this.isCollapse = true
        }
      })()
    }
  }
}
</script>

<style lang="less" scoped>
.left-content {
  // width: 240px;
  min-width: 40px;
  height: 100%;
  box-shadow: 0px 0px 17px 1px rgba(0, 2, 1, 0.1);
  i {
    margin-right: 20px;
  }
}
.el-menu-vertical-demo {
  width: 100%;
  height: 100%;
}
/deep/.is-opened {
  .el-submenu__icon-arrow {
    color: #cd0550;
  }
}
.el-menu-item.is-active {
  background: #cd0550;
}
.el-menu-item-group {
  .el-menu-item {
    padding-left: 56px !important;
  }
}
.el-menu-item {
  min-width: 40px !important;
}
/deep/.el-submenu [class^=el-icon-]{
  text-align: left !important;
  font-size: 20px !important;
}
</style>